#license-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#license-form label {
    text-align: center;
    width: 100%;
}

#license-form input {
    width: 80%;
    margin: 0 auto;
    color: var(--text-color);
    background-color: var(--light-background-color);
    width: 24ch;
    text-align: center;
}

#license-form small {
    padding-top: 4px;
    text-align: center;
}

#license-form button {
    margin: 16px auto;
}

#license-form button {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    color: var(--light-text-color);
}

#license-form button:hover {
    background-color: var(--light-text-color);
    color: var(--background-color);
}