.rst__rowWrapper {
    height: 100%;
    box-sizing: border-box;

}

.rst__rtl.rst__rowWrapper {
    padding: 10px 0 10px 10px;
}

.rst__row {
    height: 100%;
    white-space: nowrap;
    display: flex;
}

.rst__row>* {
    box-sizing: border-box;
}

/**
   * The outline of where the element will go if dropped, displayed while dragging
   */
.rst__rowLandingPad,
.rst__rowCancelPad {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.rst__rowLandingPad>*,
.rst__rowCancelPad>* {
    opacity: 0 !important;
}

.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
    background-color: lightblue;
    border: 3px dashed white;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

/**
   * Alternate appearance of the landing pad when the dragged location is invalid
   */
.rst__rowCancelPad::before {
    background-color: #e6a8ad;
}

/**
   * Nodes matching the search conditions are highlighted
   */
.rst__rowSearchMatch {
    outline: solid 3px #0080ff;
}

/**
   * The node that matches the search conditions and is currently focused
   */
.rst__rowSearchFocus {
    outline: solid 3px #fc6421;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
    display: inline-block;
    vertical-align: middle;
}

.rst__rowContents {
    position: static;
    height: 100%;
    box-shadow: none;
    padding: 0 0px 0 0px;
    border-radius: 2px;
    min-width: 230px;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: transparent;
}

.rst__rtl.rst__rowContents {
    border-right: none;
    border-left: solid #bbb 1px;
    padding: 0 10px 0 5px;
}

.rst__rowContentsDragDisabled {
    border-left: solid #bbb 1px;
}

.rst__rtl.rst__rowContentsDragDisabled {
    border-right: solid #bbb 1px;
    border-left: solid #bbb 1px;
}

.rst__rowLabel {
    flex: 0 1 auto;
    padding-right: 20px;
}

.rst__rtl.rst__rowLabel {
    padding-left: 20px;
    padding-right: inherit;
}

.rst__rowToolbar {
    flex: 0 1 auto;
    display: flex;
    margin-left: 16px;
}

.rst__moveHandle,
.rst__loadingHandle {
    height: 100%;
    width: 44px;
    background: white url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4NCjwvc3ZnPg==') no-repeat center;
    border: solid var(--border-color) 1px;
    border-radius: 5px 0px 0px 5px;
    box-shadow: none;
    cursor: move;
    z-index: 1;
}

.rst__loadingHandle {
    cursor: default;
    background: #d9d9d9;
}

@keyframes pointFade {

    0%,
    19.999%,
    100% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }
}

.rst__loadingCircle {
    width: 80%;
    height: 80%;
    margin: 10%;
    position: relative;
}

.rst__loadingCirclePoint {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.rst__rtl.rst__loadingCirclePoint {
    right: 0;
    left: initial;
}

.rst__loadingCirclePoint::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 11%;
    height: 30%;
    background-color: #fff;
    border-radius: 30%;
    animation: pointFade 800ms infinite ease-in-out both;
}

.rst__loadingCirclePoint:nth-of-type(1) {
    transform: rotate(0deg);
}

.rst__loadingCirclePoint:nth-of-type(7) {
    transform: rotate(180deg);
}

.rst__loadingCirclePoint:nth-of-type(1)::before,
.rst__loadingCirclePoint:nth-of-type(7)::before {
    animation-delay: -800ms;
}

.rst__loadingCirclePoint:nth-of-type(2) {
    transform: rotate(30deg);
}

.rst__loadingCirclePoint:nth-of-type(8) {
    transform: rotate(210deg);
}

.rst__loadingCirclePoint:nth-of-type(2)::before,
.rst__loadingCirclePoint:nth-of-type(8)::before {
    animation-delay: -666ms;
}

.rst__loadingCirclePoint:nth-of-type(3) {
    transform: rotate(60deg);
}

.rst__loadingCirclePoint:nth-of-type(9) {
    transform: rotate(240deg);
}

.rst__loadingCirclePoint:nth-of-type(3)::before,
.rst__loadingCirclePoint:nth-of-type(9)::before {
    animation-delay: -533ms;
}

.rst__loadingCirclePoint:nth-of-type(4) {
    transform: rotate(90deg);
}

.rst__loadingCirclePoint:nth-of-type(10) {
    transform: rotate(270deg);
}

.rst__loadingCirclePoint:nth-of-type(4)::before,
.rst__loadingCirclePoint:nth-of-type(10)::before {
    animation-delay: -400ms;
}

.rst__loadingCirclePoint:nth-of-type(5) {
    transform: rotate(120deg);
}

.rst__loadingCirclePoint:nth-of-type(11) {
    transform: rotate(300deg);
}

.rst__loadingCirclePoint:nth-of-type(5)::before,
.rst__loadingCirclePoint:nth-of-type(11)::before {
    animation-delay: -266ms;
}

.rst__loadingCirclePoint:nth-of-type(6) {
    transform: rotate(150deg);
}

.rst__loadingCirclePoint:nth-of-type(12) {
    transform: rotate(330deg);
}

.rst__loadingCirclePoint:nth-of-type(6)::before,
.rst__loadingCirclePoint:nth-of-type(12)::before {
    animation-delay: -133ms;
}

.rst__loadingCirclePoint:nth-of-type(7) {
    transform: rotate(180deg);
}

.rst__loadingCirclePoint:nth-of-type(13) {
    transform: rotate(360deg);
}

.rst__loadingCirclePoint:nth-of-type(7)::before,
.rst__loadingCirclePoint:nth-of-type(13)::before {
    animation-delay: 0ms;
}

.rst__rowTitle {
    font-weight: bold;
}

.rst__rowTitleWithSubtitle {
    font-size: 85%;
    display: block;
    height: 0.8rem;
}

.rst__rowSubtitle {
    font-size: 70%;
    line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
    appearance: none;
    border: none;
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #000;
    width: 16px;
    height: 16px;
    padding: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.rst__rtl.rst__collapseButton,
.rst__rtl.rst__expandButton {
    transform: translate(50%, -50%);
}

.rst__collapseButton:focus,
.rst__expandButton:focus {
    outline: none;
    box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
}

.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
    background-size: 24px;
    height: 20px;
    width: 20px;
}

.rst__collapseButton {
    background: #fff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==') no-repeat center;
}

.rst__expandButton {
    background: #fff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+') no-repeat center;
}

/**
   * Line for under a node with children
   */
.rst__lineChildren {
    height: 100%;
    display: inline-block;
    position: absolute;
}

.rst__lineChildren::after {
    content: '';
    position: absolute;
    background-color: black;
    width: 1px;
    left: 50%;
    bottom: 0;
    height: 10px;
}

.rst__rtl.rst__lineChildren::after {
    right: 50%;
    left: initial;
}