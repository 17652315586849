#center-logo {
    margin: 0 auto;
    display: block;
    text-align: center;
    padding: 32px;
}

#center-logo img {
    margin: 32px;
}

#login-container {
    width: 50%;
    margin: 12% auto 0 auto;
}

.outer {
    display: flex;
}

.wrapper {
    position: relative;

    align-items: center;
}

.circular-progress {
    color: green;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}