:root {
  --background-color: #ffffff;
  --light-background-color: #f0f0f0;
  --accent-color: #161b22;
  --border-color: #21262d;
  --text-color: #535353;
  --light-text-color: #272727;
  --white: #fff;
  --danger-color: #dc3545;
}


[data-theme="dark"] {
  /* --background-color: #ececec;
  --light-background-color: #e0e0e0;
  --accent-color: #161b22;
  --border-color: #21262d;
  --text-color: #535353;
  --light-text-color: #272727;
  --white: #fff; */
  --background-color: #0d1117;
  --light-background-color: #141414;
  --accent-color: #161b22;
  --border-color: rgba(255, 255, 255, 0.1);
  --text-color: #aaa;
  --light-text-color: #fff;
  --white: #fff;
}

.jumbotron {
  background-color: var(--background-color);
}

.react-confirm-alert-body {
  width: 500px;
}

#root {
  min-height: 100vh;
  position: relative;
}

#parent {
  min-height: 100vh;
  flex: 1;
}

body {
  min-height: 100vh;
  text-align: left;
  background-color: var(--background-color);
  color: var(--text-color);
}

button {
  padding: 8px;
}

.btn-default {
  margin: 0 auto;
  color: var(--light-text-color);
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
}

.btn-default:hover {
  color: var(--background-color);
  background-color: var(--light-text-color);
}

.btn-danger:hover {
  color: #fff;
  background-color: var(--danger-color);
}

.center {
  text-align: center;
}

.row {
  margin-top: 2rem;
}

a:hover {
  text-decoration: none;
}

a {
  color: var(--text-color);
}

.pointer:hover {
  cursor: pointer;
}

.copiable {
  background-color: var(--light-background-color);
  padding: 4px;
  border-radius: 5px;
  border: 1px dashed var(--border-color);
}

.copiable:hover {
  cursor: pointer;
}

.middle-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19); */
}

.middle-container h3 {
  border-bottom: 1px solid var(--border-color);
  text-align: center;
  font-weight: 300;
  padding-bottom: 8px;
}

.middle-container h2 {
  text-align: center;
  font-weight: 300;
  padding-bottom: 8px;
}

.middle-container p {
  text-align: center;
}


@media screen and (max-width: 1200px) {
  .middle-container {
    width: 35%;
  }
}

@media screen and (max-width: 600px) {
  .middle-container {
    width: 90%;
  }

  .middle-container {
    position: relative;
    transform: translate(-50%, 0);
    margin-top: 32px;
  }
}

/*
  Product
*/

#product-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

#product-container h4 {
  font-weight: 300;
}

.product-image {
  width: 64px;
  height: 64px;
  margin: 0 auto;
  display: block;
  margin-bottom: 8px;
  margin-top: 32px;
}

.product-name {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.product-description {
  opacity: 0.7;
}

.product-version {
  font-style: italic;
}

#platforms-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.product-actions {
  margin-top: 8px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.product-button {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin: 8px;
}

.product-button:hover {
  color: var(--background-color);
  background-color: var(--text-color);
}

.configuration-table {
  text-align: center;
  width: 100%;
}

.configuration-table td,
.configuration-table th {
  padding: 4px;
}

.config-key {
  text-transform: capitalize;
}

/* Documentation */

.header {
  margin-top: 32px;
}

.header h1 {
  font-weight: 300;
  margin-top: 4px;
}

h2,
h3,
h4 {
  font-weight: 300;
}

#chapters ul {
  list-style: none;
}

#chapters li:before {
  content: "\f0c1";
  /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em;
  /* same as padding-left set on li */
  width: 1.3em;
  /* same as padding-left set on li */
}