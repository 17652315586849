#main {
    margin-top: 64px;
    padding-bottom: 128px;
}

.rst__tree {
    position: static !important;
}

.component {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 8px;
}

/* Import */

#import-container p {
    margin: 0px;
    padding: 4px;
}

#import-container input {
    color: var(--text-color);
    background-color: var(--background-color);
    padding: 4px;
    padding-left: 8px;
    border-radius: 5px;
    margin: 2px;
}

/* Output */
.component {
    margin-top: 16px;
}

#output-actions {
    display: flex;
    justify-content: left;
    align-items: center;
}

.checklist-output {
    margin-bottom: 8px;
}


#output-actions .btn-default {
    margin: unset;
}

.error-infos {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.error {
    text-align: center;
    color: white;
    background-color: darkred;
    border-radius: 5px;
    margin-top: 8px;
    margin-bottom: 0px;
    padding: 8px;
}

.container {
    width: 100%;
    max-width: 85%;
}

@media screen and (max-width: 1920px) {
    .container {
        width: 100%;
        max-width: 95%;
    }
}

@media screen and (max-width: 1800px) {
    .container {
        width: 100%;
        max-width: 95%;
    }
}

@media screen and (max-width: 1700px) {
    .container {
        width: 100%;
        max-width: 98%;
    }
}