.detail-modal-background {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 998;
    border-radius: 5px;
}

.detail-modal-elevated {
    position: relative;
    z-index: 999;
    transform: translateZ(1px);
}

.detail-modal-content {
    width: 100%;
}