.option-adder {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 250px;
    position: absolute;
    z-index: 10;
    margin-top: 48px;
    background-color: white;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.input {
    /* border-bottom: 1px solid var(--border-color); */
    display: flex;
}

.input input {
    border: 4px;
    border-right: 1px solid var(--border-color);
    border-radius: 5px 0px 0px 5px;
    width: 100%;
    margin: 0px;
}

.b-bottom {
    border-bottom: 1px solid black;
}

.optionAddButton {
    background-color: transparent;
    color: var(--text-color);
    border: none !important;
    margin-left: auto;
    border-radius: 0px !important;
}

.optionAddButton:hover {
    color: var(--background-color);
    background-color: var(--light-text-color);
}

.optionAddButton::after {
    font-family: 'FontAwesome';
    content: '\f067';
    font-size: 1.2em;
}

.options {
    margin: 0px;
    padding: 0px;
}

.options li {
    display: flex;
    align-items: center;
}

.options p {
    margin: 0px;
    width: 85%;
    word-wrap: break-word;
}

.options li {
    list-style: none;
    padding: 4px;
    padding-left: 8px;
    position: relative;
}

.options li:not(:first-child) {
    border-top: 1px solid var(--border-color);
}

.options .delete-button {
    margin-left: auto;
    font-size: .8em;
    padding-right: 4px;
}

.options .delete-button::after {
    padding-right: 0px;
}