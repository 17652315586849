.entry {
    display: flex;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 1300px) {
    .entry {
        flex-wrap: wrap;
    }
}

.v-stack {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.h-stack {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.entry-container {
    border: 1px solid var(--border-color);
    border-radius: 0px 5px 5px 0px;
    border-left: 0px;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 8px;
}

.entry input,
.entry select,
.entry label {
    color: var(--text-color);
    border: 1px solid var(--border-color);

    padding: 4px;
    padding-left: 8px;
    border-radius: 5px;
    margin: 2px;
}

.entry input {
    background-color: var(--light-background-color);
}

.entry input,
.entry label {
    border: 0px;
}

.entry select {
    margin-left: 16px;
}

.left-auto {
    margin-left: auto !important;
}

.entry-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.fa-button {
    background-color: var(--background-color);
    border: 0px;
}

.fa-button::after {
    font-family: 'FontAwesome';
    margin: 0 auto;
    color: var(--text-color);
    padding: 2px;
}

.delete-button::after {
    content: '\f00d';
    font-size: 1.5em;
}

.delete-button:hover::after {
    color: red;
    background-color: initial;
}

.margin-top-32 {
    margin-top: 32px;
}

.height-32 {
    height: 32px;
}

/* Bottom Components */
.bottom-components {
    position: relative;
    height: 100%;
}

.children-entries {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    margin: 8px 0px 8px 48px;
}

.extra-component-2-content {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    position: absolute;
    z-index: 10;
    margin-top: 48px;
    background-color: white;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}