.actions {
    display: flex;
    justify-content: center;
    padding: 16px;
    padding-bottom: 32px;
}

.ReactVirtualized__Grid.ReactVirtualized__List.rst__virtualScrollOverride {
    position: static !important;
    overflow: visible !important;
    /* width: 100% !important;
    height: 100% !important; */
}

.tree-container {
    position: relative;
    width: 100%;
    height: 100%;
}