.checklist-properties-container hr {
    color: var(--border-color);
}

.title {
    padding: 4px;
    font-size: 1.6em;
    font-weight: 300;
    margin: 0px;
    border-bottom: 1px solid var(--border-color);
}

.checklist-properties {
    width: 100%;
    table-layout: fixed;
    margin: 8px;
}

.checklist-properties td:first-of-type {
    font-weight: 500;
}

.checklist-properties td input[type="simple_text"] {
    width: calc(100% - 8px);
    box-sizing: border-box;
}

.checklist-properties input {
    color: var(--text-color);
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    padding: 4px;
    padding-left: 8px;
    border-radius: 5px;
    margin: 2px;
}