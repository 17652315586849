.navbar {
    background-color: var(--accent-color);
}

.active {
    color: white;
}

#toggleTheme {
    padding: 8px;
    margin: 0px;
}

#navbar-logo {
    margin-right: 16px;
}

.navbar-brand {
    font-weight: 400;
}