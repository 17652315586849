.entry-attributes {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    margin: 8px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
}

@media screen and (max-width: 1700px) {
    .entry-attributes {
        flex-direction: column;
        flex-wrap: wrap;
    }
}

.entry-attribute {
    padding-left: 8px;
    display: flex;
}

.entry-attribute input {
    margin-right: 8px;
    vertical-align: middle;
}